var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('v-card',{attrs:{"outline":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("mdi-account-circle")]),_vm._v(" "),_c('span',{staticClass:"d-inline-flex"},[_vm._v("Карточка пользователя")])],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"profileForm"},[_c('validation-provider',{attrs:{"name":"email","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"name","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Имя","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"name","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Пароль","required":"","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('v-btn',{staticClass:"mt-2",attrs:{"loading":!!_vm.loading,"disabled":!!_vm.loading,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v("Обновить")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }