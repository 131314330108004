<template>
  <v-container fluid>
    <v-row align="start" justify="start">
        <v-col sm="12" md="4">
            <v-card outline>
                <v-card-title><v-icon large left>mdi-account-circle</v-icon> <span class="d-inline-flex">Карточка пользователя</span></v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <validation-observer ref="observer" v-slot="{handleSubmit}">
                                <form ref="profileForm">
                                    <validation-provider v-slot="{errors}" name="email" rules="required|max:256">
                                        <v-text-field v-model="form.email" label="Email"
                                                      :error-messages="errors" required
                                        ></v-text-field>
                                    </validation-provider>
                                    <validation-provider v-slot="{errors}" name="name" rules="required|max:256">
                                        <v-text-field
                                                v-model="form.name"
                                                :error-messages="errors"
                                                label="Имя"
                                                required
                                        ></v-text-field>
                                    </validation-provider>
                                    <validation-provider v-slot="{errors}" name="name" rules="required|max:256">
                                        <v-text-field
                                                v-model="form.password"
                                                :error-messages="errors"
                                                label="Пароль"
                                                required type="password"
                                        ></v-text-field>
                                    </validation-provider>
                                    <v-btn @click="handleSubmit(save)"
                                           :loading="!!loading" :disabled="!!loading"
                                           class="mt-2" rounded color="success"
                                    >Обновить</v-btn>
                                </form>
                            </validation-observer>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import {mapState} from 'vuex'
    import ut from '@/utils'
    import {User} from '@/store/auth'

    export default {
        name: 'profile',

        data() {
            return {
                form: ut.deepClone(User),
            }
        },

        computed: {
            ...mapState('Auth', [
                'user',
                'loading',
            ]),
        },

        methods: {
            save() {
              debugger
                this.$store
                    .dispatch('Auth/update', this.form)
                    .then(() => {
                        this.$eventBus.$emit('notification:success', 'Изменения сохранены')
                    })
            }
        },

        created() {
            this.form = ut.deepClone(this.user)
        }
    }
</script>
